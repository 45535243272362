import React, { Fragment } from "react";
import GenericWideSection from "./GenericWideSection";

const TableCellValue = (cell) => {
  const valueType = cell.fieldGroupName.split("_").at(-1);

  switch (valueType) {
    case "Text":
      return cell.text;
    case "Boolean":
      return !!cell.value && "✓";
    default:
      return null;
  }
};

const TableSection = ({ title, table, ...props }) => {
  const { columns, rows } = table;
  return (
    <GenericWideSection
      header={title}
      smallContent={false}
      textAlign="center"
      vertical
      {...props}
    >
      <div
        className="comparison-table__wrapper"
        style={{ overflowX: "scroll" }}
      >
        <table className="comparison-table">
          <tbody>
            {!!columns?.length &&
              rows?.map(({ isHeader, cells }, rowIndex) => (
                <tr key={rowIndex}>
                  {cells.map((cell, colIndex) => {
                    const col = columns[colIndex];

                    const className = `
                    ${col.emphasis ? "--emphasis" : ""} 
                    ${col.border ? "--border" : ""}
                  `;

                    const style = {
                      width: col.width,
                      minWidth: col.minWidth,
                      color: col.textColor,
                      "--text-align": col.textAlign,
                      "--background-color-light": col.backgroundColorLight,
                      "--background-color-dark": col.backgroundColorDark,
                      "--border-color": col.borderColor,
                    };

                    return (
                      <Fragment key={`${rowIndex}.${colIndex}`}>
                        {isHeader ? (
                          <th className={className} style={style}>
                            <div>
                              <TableCellValue {...cell} />
                            </div>
                          </th>
                        ) : (
                          <td className={className} style={style}>
                            <div>
                              <TableCellValue {...cell} />
                            </div>
                          </td>
                        )}
                      </Fragment>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </GenericWideSection>
  );
};

export default TableSection;
