import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const infinityProductComparisonPageData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_InfinityProductComparison {
                  infinityProductComparisonRichContent {
                    # SECTION: Hero
                    hero {
                      category
                      header
                      secondHeader
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      image {
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                        }
                      }
                    }

                    # SECTION: Table Section
                    tableSection {
                      title
                      table {
                        columns {
                          backgroundColorDark
                          backgroundColorLight
                          border
                          borderColor
                          emphasis
                          minWidth
                          textAlign
                          textColor
                          width
                        }
                        rows {
                          isHeader
                          cells {
                            ... on WpTemplate_InfinityProductComparison_Infinityproductcomparisonrichcontent_TableSection_Table_rows_Cells_Text {
                              fieldGroupName
                              text
                            }
                            ... on WpTemplate_InfinityProductComparison_Infinityproductcomparisonrichcontent_TableSection_Table_rows_Cells_Boolean {
                              fieldGroupName
                              value
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Image Section
                    imageSection {
                      category
                      title
                      text
                      slug
                      variant
                      image {
                        ...wpImageFluid
                      }
                      items {
                        category
                        title
                        text
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(
    data,
    id,
    "infinityProductComparisonRichContent"
  );
};

export { infinityProductComparisonPageData };
